import React from "react";
import styled from "styled-components";
import "../../../index.css";
const HeaderContainer = styled.div`
  margin-bottom: 30px;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 0;
    margin-right: 0px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 0;
    margin-right: 0px;
    margin-bottom: 0px;
  }
`;
const H2 = styled.h1`
  font-size: 2.5rem;
  font-weight: 900;
  color: #000;
  letter-spacing: 0.055em;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1.7rem;
    color: #fff;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1rem;
    color: #fff;
  }
`;

const H3 = styled.h3`
  font-size: 1.3rem;
  color: #000;
  letter-spacing: 0.085em;
  width: 50%;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
    width: 90%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1rem;
    width: 90%;
  }
`;
const H4 = styled.h2`
  margin-top: -5px;
  font-size: 1.5rem;
  color: #000;
  letter-spacing: 0.04em;
  font-weight: 900;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 0.8rem;
    color: #fff;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1rem;
    color: #fff;
  }
`;
const HeaderTxt = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  @media (max-width: 768px) and (min-width: 300px) {
    margin-left: 0rem;
    margin-top: 1rem;
    text-align: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-left: 0rem;
    text-align: center;
    margin-top: 1rem;
  }
`;
const Tel = styled.span`
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 25px;
  padding-top: 30px;
  font-size: 1.5rem;
  direction: rtl;
  color: #6a6868;
  font-weight: 600;
  @media (max-width: 768px) {
    padding-top: 5px;
    font-size: 1rem;
    margin-right: 40px;
  }
`;
const TelDiv = styled.div`
  height: auto;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
`;

const PriceSection = styled.div`
  margin-top: 20px;
  display: flex;
  background-color: #e9e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TwoColumns = styled.div`
  width: 100%;
  height: auto;
  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    margin: 1rem 0;
  }
`;
const ImgLogo = styled.img`
  height: 20px;
  margin-left: 3px;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 14px;
  }
`;

export default function header() {
  return (
    <>
      <HeaderContainer>
        <div class="mainPage-bg">
          <HeaderTxt>
            <H2>Mattservicen</H2>
            <H4> Lagning, uppfräschning,</H4>
            <H4>tvättning och borttagning av fläckar. </H4>
          </HeaderTxt>
        </div>
        <TelDiv>
          <TwoColumns>
            <a href={"tel:" + "08219016"}>
              <Tel>
                <ImgLogo src="https://firebasestorage.googleapis.com/v0/b/nordicbazar-76aa7.appspot.com/o/Icons%2FNordicbazar-contactus-Icon.png?alt=media&token=0f3882f1-f466-48bc-811a-862dc88d8652" />{" "}
                08 219016{" "}
              </Tel>
            </a>
            <a href={"tel:" + "0732323991 "}>
              <Tel>
                {" "}
                <ImgLogo src="https://firebasestorage.googleapis.com/v0/b/nordicbazar-76aa7.appspot.com/o/Icons%2FNordicbazar-contactus-Icon.png?alt=media&token=0f3882f1-f466-48bc-811a-862dc88d8652" />{" "}
                073 23 23 991{" "}
              </Tel>
            </a>
          </TwoColumns>
          <a href="mailto:Info@mattservicen.se">
            <Tel>Info@mattservicen.se</Tel>
          </a>
        </TelDiv>
      </HeaderContainer>
      <PriceSection>
        <H3>
          Vi erbjuder professionell rengöring, tvätt och reparation av alla
          typer av mattor. Priserna börjar på 139 kronor för maskintillverkade
          mattor och 185 kronor för handgjorda mattor. Detta erbjudande gäller
          till slutet av året. Kvalitetsservice till bra priser!
        </H3>
      </PriceSection>
    </>
  );
}
