import { useState } from "react";
import Modal from "react-modal";
import TopContainer from "../Top/topContainer";
import styled from "styled-components";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./contactus.css";
import Footer from "../Footer/footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import { ApiStatus, Colors, Sizes } from "../../Constants/Constants";
import InputField from "../../Shared/InputField/InputField";
import Button from "../../Shared/Button/Button";
import {
  makeIdleSendAskForPriceEmail,
  sendAskForPriceEmail,
} from "../../../store/actions/sendEmail";
import SpinnerLinear from "../../Shared/SpinnerLinear/SpinnerLinear";

const Textarea = styled.textarea`
  resize: none;
  margin: ${Sizes.Small} 0;
  width: 100%;
  height: ${Sizes.XXXLarge};
  @media (max-width: 768px) and (min-width: 300px) {
    width: 100%;
    height: ${Sizes.XLarge};
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.p`
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 25px;
  padding-top: 30px;
  font-size: ${Sizes.Medium};
  direction: rtl;
  color: #6a6868;
  font-weight: 700;
  @media (max-width: 768px) and (min-width: 300px) {
    padding-top: 5px;
    margin-right: 10px;
    font-size: 0.8rem;
  }
`;
const ModalTitle = styled.p`
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 25px;
  padding-top: 30px;
  font-size: 2rem;
  direction: rtl;
  color: #6a6868;
  font-weight: 700;
  @media (max-width: 768px) and (min-width: 300px) {
    padding-top: 5px;
    margin-right: 10px;
    font-size: 0.8rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding-top: 5px;
    margin-right: 5px;
    font-size: 1rem;
  }
`;
const Tel = styled.p`
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 25px;
  padding-top: 30px;
  font-size: 1.5rem;
  direction: rtl;
  color: #6a6868;
  font-weight: 500;
  @media (max-width: 768px) and (min-width: 300px) {
    padding-top: 5px;
    margin-right: 10px;
    font-size: 0.8rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding-top: 5px;
    margin-right: 5px;
    font-size: 1rem;
  }
`;
const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: #eff0f1;
  padding-bottom: 0rem;
  @media (max-width: 768px) and (min-width: 300px) {
    padding-bottom: 0rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding-bottom: 0rem;
  }
`;

const InnerContainer = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 30px 0;
  flex-direction: row;
  justify-content: center;
`;
const TelDiv = styled.div`
  height: auto;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) and (min-width: 300px) {
    margin: 1rem 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;

const TwoColumns = styled.div`
  width: 100%;
  height: auto;
  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    margin: 1rem 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;

const Img = styled.img`
  object-fit: cover;

  height: 26rem;
`;
const ImgLogo = styled.img`
  height: 20px;
  margin-left: 3px;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 14px;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    height: 16px;
  }
`;

const Iframe = styled.iframe`
  width: 500px;
  height: 650px;
  border: 1px solid ${Colors.Gray};
  @media (max-width: 768px) and (min-width: 300px) {
    width: 100%;
    height: auto;
  }
`;

export const Contactus = () => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const apiStatus = useSelector(
    (state) => state.apiStatus.sendEmailAskForPrice
  );
  const [state, setState] = useState({
    name: "",
    tel: "",
    email: "",
    message: "",
  });
  const onChangeHandler = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const sendEmail = () => {
    dispatch(sendAskForPriceEmail(state));
    setModalShow(true);
  };

  const onCancelClick = () => {
    setModalShow(false);
    dispatch(makeIdleSendAskForPriceEmail());
  };

  return (
    <>
      <Modal
        isOpen={modalShow}
        ariaHideApp={false}
        className="mymodal"
        overlayClassName="myoverlay"
        onRequestClose={onCancelClick}
        closeTimeoutMS={500}
      >
        {apiStatus === ApiStatus.Started && <SpinnerLinear />}
        {apiStatus === ApiStatus.Completed && (
          <>
            <ModalTitle>Tack!</ModalTitle>
            <Tel>Vi vill kontakta dig inom kort</Tel>

            <ButtonsContainer>
              <Button
                title="Stäng"
                onClick={onCancelClick}
                disabled={apiStatus === ApiStatus.Started}
              />
            </ButtonsContainer>
          </>
        )}
      </Modal>
      <Container>
        <TopContainer />

        <Carousel
          showThumbs={false}
          autoPlay={true}
          showIndicators={false}
          showArrows={false}
          infiniteLoop={true}
        >
          <div>
            <Img src={require("../../../assets/contactus-01.jpg")} />
          </div>
          <div>
            <Img src={require("../../../assets/contactus-02.jpg")} />
          </div>
        </Carousel>
        <InnerContainer>
          <TwoColumns className="contactus-tel-adress">
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2042.506934526!2d17.584731816239522!3d59.207540881616325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f0d86e366de3f%3A0x5e7a27c727d882f6!2zQnLDpG5uYXJldsOkZ2VuIDksIDE1MSA1NSBTw7ZkZXJ0w6RsamU!5e0!3m2!1sen!2sse!4v1665851036135!5m2!1sen!2sse"
              frameBorder="0"
              allowFullScreen
            />

            <TelDiv>
              <a href={"tel:" + "08219016"}>
                <Tel>
                  <ImgLogo src="https://firebasestorage.googleapis.com/v0/b/nordicbazar-76aa7.appspot.com/o/Icons%2FNordicbazar-contactus-Icon.png?alt=media&token=0f3882f1-f466-48bc-811a-862dc88d8652" />{" "}
                  08219016{" "}
                </Tel>
              </a>
              <a href={"tel:" + "0732323991 "}>
                <Tel>
                  <ImgLogo src="https://firebasestorage.googleapis.com/v0/b/nordicbazar-76aa7.appspot.com/o/Icons%2FNordicbazar-contactus-Icon.png?alt=media&token=0f3882f1-f466-48bc-811a-862dc88d8652" />{" "}
                  0732323991
                </Tel>
              </a>

              {/* <Tel>073 23 23 991  <ImgLogo src="https://firebasestorage.googleapis.com/v0/b/nordicbazar-76aa7.appspot.com/o/Icons%2FNordicbazar-contactus-Icon.png?alt=media&token=0f3882f1-f466-48bc-811a-862dc88d8652" /></Tel> */}

              <a href="mailto:Info@mattservicen.se">
                <Tel>Info@mattservicen.se</Tel>
              </a>
            </TelDiv>
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16240.339777232337!2d17.88963461392116!3d59.415686466201386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9f1eb1c936cd%3A0x5e1e12e100b7eaf1!2sMariehamnsgatan%208%2C%20164%2071%20Kista!5e0!3m2!1sen!2sse!4v1573992964256!5m2!1sen!2sse"
              frameBorder="0"
              allowFullScreen
            />
          </TwoColumns>
          <Title>Kontakta Oss</Title>
          <InputField
            name="name"
            placeholder="Namn"
            onChange={onChangeHandler}
          />
          <InputField
            name="email"
            type="email"
            placeholder="E-postadress"
            onChange={onChangeHandler}
          />
          <InputField
            name="tel"
            type="tel"
            placeholder="Telefon"
            onChange={onChangeHandler}
          />
          <Textarea
            name="message"
            placeholder="Meddelande"
            onChange={onChangeHandler}
          />
          <ButtonContainer>
            <Button primary title="Skicka" onClick={sendEmail} />
          </ButtonContainer>
        </InnerContainer>
        <Footer></Footer>
      </Container>
    </>
  );
};

export default Contactus;
